@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Raleway");

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

body {
  background-image: url("./blob.svg");
  background-repeat: no-repeat;
  background-size: 1200px;
  background-position: 90% -5%;
}

section#projects {
  background-image: url("./blob2.svg");
  background-repeat: no-repeat;
  background-size: 1300px;
  background-position: -50% -100%;
}
